// our components
require('../../section-specific/travel-medical/quote-form_travel-medical');
require('../../components/forms/component.quote-form-age-modal');
require('../../components/forms/component.quote-form-covid-location');
import miniQuote from '../../components/component.lnd-quote-form.vue';
import quoteFormMixin from '../../quote-form-mixin';
import MainVue from '../../main-vue';

new MainVue({
    el: '#app',
    mixins: [quoteFormMixin],
    components: {
        'mini-quote': miniQuote
    },
    data() {
        return {
            section: window.globalLanguage.section
        }
    },
    methods: {
           
    }
});

new MainVue({
    el: '#home-card-blocks-and-guide',
    data: function() {
        return {
            affiliateTripInsurance: ibJS.affiliateTripInsurance,
            affiliateAnnualMultitrip: ibJS.affiliateAnnualMultitrip,
            affiliateDental: ibJS.affiliateDental,
            affiliateGroupMedical: ibJS.affiliateGroupMedical,
            affiliateSchengeVisa: ibJS.affiliateSchengeVisa,
            affiliateMissionaryLongTerm: ibJS.affiliateMissionaryLongTerm,
            affiliateGroupMedicalInsurance: ibJS.affiliateGroupMedicalInsurance,
            affiliateVisitorsToUsa: ibJS.affiliateVisitorsToUsa,
            affiliateVisitorOutsideUSA: ibJS.affiliateVisitorOutsideUSA,
            affiliateIndividualMedical: ibJS.affiliateIndividualMedical,
            affiliateFianceVisa: ibJS.affiliateFianceVisa,
            affiliateJVisa: ibJS.affiliateJVisa,
            affiliateIntlStudent: ibJS.affiliateIntlStudent,
            isFAQItemVisible: null,
        }
    },
    methods: {
        getIntroCardsCount: function(){
            //Initiate counter
            var counter = 0;

            //Visitors To USA Section
            if(window.globalLanguage.section == "visitorUSA") {
                //Arrays of true/false block section values
                var sectionCards = [this.affiliateTripInsurance,this.affiliateAnnualMultitrip,this.affiliateDental,this.affiliateGroupMedical];
            }
            else if(window.globalLanguage.section == "newImmigrants") {
                var sectionCards = [this.affiliateIndividualMedical,this.affiliateFianceVisa,this.affiliateDental,this.affiliateTripInsurance];
            }
            else if(window.globalLanguage.section == "travelOutsideUSA") {
                var sectionCards = [this.affiliateTripInsurance,this.affiliateAnnualMultitrip,this.affiliateSchengeVisa,this.affiliateGroupMedical];
            }
            else if(window.globalLanguage.section == "schengen") {
                var sectionCards = [this.affiliateVisitorOutsideUSA,this.affiliateAnnualMultitrip,this.affiliateTripInsurance,this.affiliateGroupMedicalInsurance];
            }
            else if(window.globalLanguage.section == "studyAbroad") {
                var sectionCards = [this.affiliateSchengeVisa,this.affiliateTripInsurance,this.affiliateJVisa,this.affiliateIntlStudent];
            }            
            else if(window.globalLanguage.section == "missionaryTravel") {
                var sectionCards = [this.affiliateMissionaryLongTerm,this.affiliateTripInsurance,this.affiliateGroupMedicalInsurance,this.affiliateVisitorsToUsa];
            }
            else if(window.globalLanguage.section == "covid19") {
                var sectionCards = [this.affiliateMissionaryLongTerm,this.affiliateTripInsurance,this.affiliateGroupMedicalInsurance,this.affiliateVisitorsToUsa];
            }

            //Loop and count true/false values
            sectionCards.forEach(v => v ? counter++ : v);

            return counter;
        },
        getIntroCardsModifierClass(itemCount, masterClass) {
            //Return modifier class name to adjust intro card styles
            if(itemCount==1) {
                return masterClass + "--one";
            }
            else if(itemCount==2) {
                return masterClass + "--two";
            }
            else if(itemCount==3) {
                return masterClass + "--three";
            }
            else if(itemCount==4) {
                return masterClass + "--four";
            }
        },
        toggleFAQItem: function(value) {
            
            if(this.isFAQItemVisible!==value){
                this.isFAQItemVisible = value;
            }
            else {
                this.isFAQItemVisible = null;
            }
        
        },
    }
});