<template>
    <form action="#" class="c-QuoteWidget" autocomplete="off"
        v-on:submit.prevent="getQutoes"
        novalidate>
        <div class="c-QuoteWidget__field">
            <multiselect 
                class="c-Quote-Form__multiselect c-Quote-Form__multiselect-default"
                ref="citizenshipCountry" 
                :id="firstField" 
                v-model="formWidget[firstField]" 
                :options="allCountries" 
                :close-on-select="true" 
                :name="firstField" 
                track-by="newCode" 
                open-direction="bottom"
                label="country" 
                :placeholder="langLabel[firstField] || qFormLang.select"
                :deselect-label="langLabel.deselect" 
                :selected-label="langLabel.selected"
                :searchable="searchable"
                :select-label="langLabel.select"
                :max-height="320"
                :allow-empty="false"
                @select="selectCitizenshipCountry"
                @open="$emit('set-focus', 'citizenshipCountry')">
                <span slot="noResult" v-cloak>{{langLabel.noResults}}</span>
            </multiselect>
            <div class="o-Valid-msg" v-cloak> 
                <p v-if="$v.formWidget[firstField].$error && $v.formWidget[firstField].required == false">
                    {{langLabel[firstField]}}{{ langLabel.error.required }}
                </p>
            </div>
        </div>
        <div class="c-QuoteWidget__field">
            <div class="c-DRange" ref="dpcikCtrl">
                <div class="c-DRange__start">
                    <date-picker 
                        ref="startDatePicker"
                        v-model.trim="formWidget.startDate" 
                        :qResultsLang="qFormLang" 
                        :start-date="formWidget.startDate ? new Date() : new Date()"
                        inline-template
                    >
                        <div>
                            <input type="text"
                                id="start"
                                :value="value || ''"
                                class="o-Form-control"
                                :placeholder="qResultsLang.fields.startDate"
                                autocomplete="off"
                                @input="handleInputEvt"
                            />
                            <span class="o-Icon-menu__date" style="visibility: hidden;"></span>
                        </div>
                    </date-picker>
                </div>
                <div class="c-DRange__divider"></div>
                <div class="c-DRange__end">
                    <date-picker 
                        ref="endDatePicker"
                        v-model.trim="formWidget.endDate" 
                        :qResultsLang="qFormLang" 
                        :start-date="formWidget.startDate ? formWidget.startDate : new Date()"
                        inline-template
                    >
                        <div>
                            <input type="text"
                                id="end"
                                :value="value || ''"
                                class="o-Form-control"
                                :placeholder="qResultsLang.fields.endDate"
                                autocomplete="off"
                                @input="handleInputEvt"
                            />
                            <span class="o-Icon-menu__date" style="visibility: hidden;"></span>
                        </div>
                    </date-picker>
                </div>
            </div>
            <div class="o-Valid-msg" v-cloak>
                <p v-if="($v.formWidget.startDate.$error && !$v.formWidget.startDate.required) || $v.formWidget.endDate.$error && !$v.formWidget.endDate.required">
                    {{langLabel.dates}}{{langLabel.error.required}}
                </p>
                <p v-else-if="($v.formWidget.startDate.$error && !$v.formWidget.startDate.numbersWithDashSlash) || ($v.formWidget.endDate.$error && !$v.formWidget.endDate.numbersWithDashSlash)">
                    {{langLabel.dates}}{{langLabel.error.noSpecialCharacters}}
                </p>
                <p v-else-if="($v.formWidget.startDate.$error && !$v.formWidget.startDate.noAlphabets) || ($v.formWidget.endDate.$error && !$v.formWidget.endDate.noAlphabets)">
                    {{langLabel.dates}}{{langLabel.error.noAlphabetsAllowed}}
                </p>
                <p v-else-if="$v.formWidget.$dirty && (($v.formWidget.startDate.required && !$v.formWidget.startDate.monthDate) || ($v.formWidget.endDate.required && !$v.formWidget.endDate.monthDate))">
                    {{ langLabel.error.dateFormat }}
                </p>
                <p v-else-if="!$v.formWidget.startDate.$error &&
                    $v.formWidget.startDate.required &&
                    $v.formWidget.endDate.$error &&
                    $v.formWidget.endDate.required &&
                    !$v.formWidget.endDate.isAfterStartDate">
                    {{ langLabel.error.dateRangeAfter }}
                </p>
            </div>
        </div>
        <a 
            id="getQuotesLandingWidget" 
            class="o-Btn o-Btn--prim o-Form__btn c-Home__btn c-Home-cro-v2__btn quotes-btn-link c-QuoteWidget__btn" 
            :href="langLabel.getNewQuoteForm + '?presection=' + section" 
            @click.prevent="getQutoes"
        >{{ langLabel.getQuoteBtn }}</a>
    </form>
</template>

<script>
import Vue from 'vue';
import VueResource from 'vue-resource';
import MultiSelect from 'vue-multiselect';
import { Vuelidate } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { monthDate, numbersWithDashSlash, noAlphabets, isAfterField, getCountriesFromStorage, isSchengenCountry } from '../helper-functions/helper-functions';
import { getItemSessionStorage, setItemSessionStorage } from '../helper-functions/services';
import datePicker from '../components/forms/component.date-picker.vue';

Vue.use(Vuelidate);
Vue.use(VueResource);

    export default {
        name: 'mini-quote',
        props: ['firstField', 'label', 'placeholder', 'form'],
        components: {
            'date-picker': datePicker,
            'multiselect': MultiSelect,
        },
        data () {
            return {
                allCountries: [],
                qFormLang: window.qFormLang,
                searchable: true,
                formWidget: {
                    startDate: '',
                    endDate: '',
                    citizenshipCountry: '',
                    destination: ''
                },
                section: window.globalLanguage.section,
                langLabel: {
                    error: {}
                }
            }
        },
        validations () {
            let validations = {
                formWidget: {
                    citizenshipCountry: {
                        
                    },
                    startDate: {
                        required,
                        noAlphabets,
                        numbersWithDashSlash,
                        monthDate,
                    },
                    endDate: {
                        required,
                        noAlphabets,
                        numbersWithDashSlash,
                        monthDate,
                        isAfterStartDate: isAfterField('startDate')
                    },
                    destination: {

                    }
                }
            }
            if (this.section === 'visitorUSA' || this.section === 'schengen') {
                validations.formWidget.citizenshipCountry.required = required;
            } else if (['travelOutsideUSA','studyAbroad','covid19','groupTravelMedical','trip'].indexOf(this.section) > -1) {
                validations.formWidget.destination.required = required;
            }
            
            return validations;
        },
        created () {
            let checkStorage = setInterval(() => {
                const masterList = getCountriesFromStorage();
                if (masterList) {
                    let popularCountries = [];
                    let allCountries = [];
                    if (this.section === 'travelOutsideUSA') {
                        // The list for Travel excluding USA shouldn't have the USA
                        popularCountries = masterList.popularCountries.filter(cntry => cntry.newCode != 'USA');
                        allCountries = masterList.countries.filter(cntry => cntry.newCode != 'USA');
                        this.allCountries = [...popularCountries, ...allCountries];
                    } else {
                        popularCountries = [...masterList.popularCountries];
                        if (this.section === 'trip') {
                            const usaCountry = popularCountries.pop();
                            popularCountries.unshift(usaCountry);
                        }
                        allCountries = [...masterList.countries];
                        this.allCountries = [...popularCountries, ...allCountries];
                    }
                    clearInterval(checkStorage);
                    checkStorage = null;
                }
            }, 30);
                
            let requestURL = location.hostname;
            if (location.hostname === 'brokersnexustest.com') {
                requestURL = 'insubuy20test';
            }
            else if (location.hostname.indexOf('brokersnexus') > -1) { // BN
                requestURL = 'sample.brokersnexus.com';
            }
            else if (location.port !== '') {
                requestURL = `${location.hostname}:${location.port}`;
            }

            let langLabel = getItemSessionStorage(`miniQForm${window.globalLanguage.lang}`);
            langLabel = langLabel ? JSON.parse(langLabel) : null;

            if (langLabel && langLabel.lang === window.globalLanguage.lang) {
                 this.langLabel = langLabel.miniQForm;
            } else {
                this.$http.get('https://' + requestURL + '/api/get-properties/?fileName=mini-quote-form&lang=' + window.globalLanguage.lang).then(res => {
                    if (res && res.body?.miniQForm) {
                        this.langLabel = res.body.miniQForm;
                        // Store in Session
                        let langObj = {
                            miniQForm: res.body.miniQForm,
                            lang: window.globalLanguage.lang
                        };
                        
                        setItemSessionStorage(`miniQForm${window.globalLanguage.lang}`, JSON.stringify(langObj));
                    }
                });
            }
        },
        mounted () {
            if (window.ibJS.qrySection) {
                let qryParam = window.ibJS.qrySection || null;
                let formStored = getItemSessionStorage("insubuy-travel-quote-form-resultObjs");
                if(formStored && JSON.parse(formStored)) {
                    const parsedVal = JSON.parse(formStored);
                }
            }
            const $quoteSection = document.querySelector(".c-Home-cro-v2__hero-quote-section");
            if ($quoteSection) {
                $quoteSection.classList.remove('c-Home-cro-v2__quoteWidget');
            }
            this.$refs.citizenshipCountry.$el.tabIndex = '0';
            const $inputStart = document.querySelector('#start');
            const $inputEnd = document.querySelector('#end');
            const $dRange = document.querySelector('.c-DRange');
            if ($inputStart) {
                $inputStart.addEventListener('focus', (e) => {
                    $dRange.classList.add('c-DRange--focus');
                });
                $inputEnd.addEventListener('focus', (e) => {
                    $dRange.classList.add('c-DRange--focus');
                });
                $inputStart.addEventListener('blur', (e) => {
                    $dRange.classList.remove('c-DRange--focus');
                });
                $inputEnd.addEventListener('blur', (e) => {
                    $dRange.classList.remove('c-DRange--focus');
                });
            }
        },
        methods: {
            selectCitizenshipCountry () {

            },
            getQutoes () {
                const qFormLang = window.qFormLang;
                this.$v.formWidget.$touch();
                if (!this.$v.$invalid) {
                    let startDate = this.formWidget.startDate;
                    let endDate = this.formWidget.endDate;
                    if(window.globalLanguage.lang == 'zh') {
                        let date = startDate.split('-');
                        startDate = date[1] + '/' + date[2] + '/' + date[0];
                        let eDate = endDate.split('-');
                        endDate = eDate[1] + '/' + eDate[2] + '/' + eDate[0];
                    } else if(window.globalLanguage.lang == 'es') {
                        let date = startDate.split('/');
                        startDate = date[1] + '/' + date[0] + '/' + date[2];
                        let eDate = endDate.split('/');
                        endDate = eDate[1] + '/' + eDate[0] + '/' + eDate[2];
                    }

                    const citizenshipCountry = (this.section === 'visitorUSA' || this.section === 'schengen') ? this.formWidget.citizenshipCountry.newCode : null;
                    const data = {
                        coverageArea: "1",
                        currentLang: window.globalLanguage.lang,
                        startDate,
                        endDate,
                        citizenshipCountry,
                        primaryDestination: this.section === 'visitorUSA' ? "USA" : this.formWidget.destination.newCode,
                        widget: true
                    };
                    if (this.section === 'trip') {
                        data.departureDate = startDate;
                        data.returnDate = endDate;
                    }
                    setItemSessionStorage('insubuy-travel-quote-form-resultObjs', JSON.stringify({
                        ...data,
                        presection: this.section,
                    }));
                    Vue.nextTick(()=> {
                        window.location.href = `${qFormLang.getNewQuoteForm}?presection=${this.section}`
                    })
                }
            }
        }
    }
</script>

<style>
.c-QuoteWidget__field .multiselect__single,
.c-QuoteWidget__field .multiselect__input {
    padding: 0;
    margin-bottom: 0;
}

.c-QuoteWidget__field .multiselect__tags {
    height: 60px;
    border-radius: 50px !important;
    display: flex;
    flex-flow: row;
    justify-content: start;
    align-items: center;
    border: 1px solid #bababa;
    padding: 0 1.625em;
}

.c-QuoteWidget__field .multiselect .multiselect__tags .multiselect__single {
    font-weight: 500;
    font-size: 1em;
    color: #000;
}

.c-QuoteWidget__field .multiselect .multiselect__tags > .multiselect__single {
    width: 99%;
}

.c-QuoteWidget__field .multiselect:focus-within {
    border-radius: 50px;
}

.c-DRange--focus {
    border-color: transparent;
    outline: 1px solid #4D90FE;
    border-radius: 50px;
}
</style>